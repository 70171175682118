import React, { useEffect, useState } from 'react'
import Prism from 'prismjs'
import sql from 'prismjs/components/prism-sql'
import java from 'prismjs/components/prism-java'
import csharp from 'prismjs/components/prism-csharp'
import nodejs from 'prismjs/components/prism-javascript'
import python from 'prismjs/components/prism-python'
import go from 'prismjs/components/prism-go'

const NewToCouchbaseCode = () => {
  useEffect(() => Prism.highlightAll([sql, java, csharp, nodejs, python, go]), [])
  const [displayCodeFor, setDisplayCodeFor] = useState('java')
  return (
    <div className="page--new-to-couchbase-code m-8 mb-12 wrapper">

      <div class="grid grid-cols-1 divide-y-2 divide-gray-500">

        {/* *********************************** NoSQL with SQL++ ************************************ */}
        <div className="w-full text-center">
          <h1>NoSQL with SQL++</h1>
          <p><strong>"Couchbase is both NoSQL and SQL??"</strong></p>
          <p class="text-gray-700 text-base">
            <strong>1.</strong> high-performance <strong>NoSQL</strong> API's for JSON documents
          </p>
          <p class="text-gray-700 text-base">
            <strong>2.</strong> and, the <strong>SQL++</strong> query language -- a.k.a. SQL for JSON
          </p>
          <br />
          <div className="mobile_data-tabs p-4 md:p-8">
            <p>Couchbase's implementation of SQL++ is called N1QL ("nickel")...</p>
            <pre className="language-sql pre codeblock line-numbers prism">
              <code className="sql">
                {`
/* Find the vote count for each nested comment from each blog post JSON doc... */

SELECT posts.comments[*].votes AS votes
FROM posts;

/* The results...
  { "results": [
      { "votes": [ 0, 13 ] },
      { "votes": [ 2, 20, 3, 0 ] }
    ]
  }
*/
`}
              </code>
            </pre>
          </div>
        </div>

        {/* *********************************** Inserting JSON ************************************ */}
        <div className="w-full text-center">
          <h2>Inserting JSON</h2>
          <p><strong>"How can I insert a JSON doc into Couchbase?"</strong></p>
          <div className="mobile_data-tabs p-4 md:p-8">
            <p>From your application, call the NoSQL insert API of the Couchbase SDK…​</p>
            <div className="mb-2">
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'java' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('java')}
              >Java</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'c' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('c')}
              >C#</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'javascript' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('javascript')}
              >Node.js</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'python' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('python')}
              >Python</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'php' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('php')}
              >PHP</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'go' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('go')}
              >Go</button>
            </div>

            {/* Java */}
            <span className={`mt-3 ${displayCodeFor !== 'java' ? 'hidden' : ''} java`}>
              <pre className="language-java pre codeblock line-numbers prism">
                <code data-language="java">
                  {`/* Connect to a couchbase collection... */

Cluster cluster = Cluster.connect(
  "couchbase://localhost", "username", "password");

Bucket bucket = cluster.bucket("posts");

Collection collection = bucket.defaultCollection();

/* Let's insert! */

MutationResult result = collection.insert(
    "post-1234",
    JsonObject.create()
              .put("title", "My Latest Blog Post"));`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/java-sdk/current/howtos/kv-operations.html">SDK docs for Java</a></p>
            </span>

            {/* C# */}
            <span className={`mt-3 ${displayCodeFor !== 'c' ? 'hidden' : ''} c`}>
              <pre className="language-csharp pre codeblock line-numbers prism">
                <code data-language="c">
                  {`/* Connect to a couchbase collection... */

var cluster = await Cluster.ConnectAsync(
  "couchbase://localhost", "username", "password");

var bucket = await cluster.BucketAsync("posts");

var collection = bucket.DefaultCollection();

/* Let's insert! */

var result = await collection.InsertAsync(
    "post-1234",
     new { title = "My Latest Blog Post" });`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/dotnet-sdk/current/howtos/kv-operations.html">SDK docs for .NET</a></p>
            </span>

            {/* Node.js */}
            <span className={`mt-3 ${displayCodeFor !== 'javascript' ? 'hidden' : ''} javascript`}>
              <pre className="language-javascript pre codeblock line-numbers prism">
                <code data-language="javascript">
                  {`/* Connect to a couchbase collection... */

const cluster = new couchbase.Cluster(
  "couchbase://localhost",
  { username: "username", password: "password" });

const bucket = cluster.bucket("posts");

const collection = bucket.defaultCollection();

/* Let's insert! */

const result = await collection.insert(
    "post-1234",
    { "title": "My Latest Blog Post" });`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/nodejs-sdk/current/howtos/kv-operations.html">SDK docs for Node.js</a></p>
            </span>

            {/* Python */}
            <span className={`mt-3 ${displayCodeFor !== 'python' ? 'hidden' : ''} python`}>
              <pre className="language-python pre codeblock line-numbers prism">
                <code data-language="python">
                  {`# Connect to a couchbase collection...

cluster = Cluster(
    'couchbase://localhost',
    ClusterOptions(PasswordAuthenticator('username', 'password')))

bucket = cluster.bucket('posts')

collection = bucket.default_collection()

# Let's insert!

result = collection.insert(
    'post-1234',
    { 'title': 'My Latest Blog Post' })`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/python-sdk/current/howtos/kv-operations.html">SDK docs for Python</a></p>
            </span>

            {/* PHP */}
            <span className={`mt-3 ${displayCodeFor !== 'php' ? 'hidden' : ''} php`}>
              <pre className="language-clike pre codeblock line-numbers prism">
                <code data-language="php">
                  {`// Connect to a couchbase collection...

$options = new \\Couchbase\\ClusterOptions();
$options->credentials("username", "password");

$cluster = new \\Couchbase\\Cluster("couchbase://localhost", $options);

$bucket = $cluster->bucket("posts");

$collection = $bucket->defaultCollection();

// Let's insert!

$result = $collection->insert(
    "post-1234",
    [ "title" => "My Latest Blog Post" ]);`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/php-sdk/current/howtos/kv-operations.html">SDK docs for PHP</a></p>
            </span>

            {/* Go */}
            <span className={`mt-3 ${displayCodeFor !== 'go' ? 'hidden' : ''} go`}>
              <pre className="language-go pre codeblock line-numbers prism">
                <code data-language="go">
                  {`// Connect to a couchbase collection...

cluster, err := gocb.Connect(
    "couchbase://localhost",
    gocb.ClusterOptions{ Username: "username", Password: "password" })

bucket := cluster.Bucket("posts")

collection := bucket.DefaultCollection()

// Let's insert!

result, err := collection.Insert(
    "post-1234",
    map[string]string{"title": "My Latest Blog Post"},
    &gocb.InsertOptions{})`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/go-sdk/current/howtos/kv-operations.html">SDK docs for Go</a></p>
            </span>

            <p> And, you can use a N1QL <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/insert.html">INSERT statement...</a></p>

            <pre className="language-sql pre codeblock line-numbers prism">
              <code className="sql">
                {`INSERT INTO posts ( KEY, VALUE )
       VALUES ( "post-1234", { "title": "My Latest Blog Post" } );`}
              </code>
            </pre>

            <br />
            <p>There are more operations to <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/update.html">UPDATE</a> existing
              data, <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/upsert.html">UPSERT</a> data, and more.</p>
          </div>
        </div>


        {/* *********************************** Lookup a JSON doc by key ************************************ */}
        <div className="w-full text-center">
          <h2>Lookup a JSON doc by key</h2>
          <p><strong>"How can I retrieve my JSON document?"</strong></p>
          <div className="mobile_data-tabs p-4 md:p-8">
            <div className="mb-2">
              <p>The NoSQL get API of the Couchbase SDK works like this…​</p>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'java' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('java')}
              >Java</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'c' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('c')}
              >C#</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'javascript' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('javascript')}
              >Node.js</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'python' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('python')}
              >Python</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'php' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('php')}
              >PHP</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'go' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('go')}
              >go</button>
            </div>

            {/* Java */}
            <span className={`mt-3 ${displayCodeFor !== 'java' ? 'hidden' : ''} java`}>
              <pre className="language-java pre codeblock line-numbers prism">
                <code data-language="java">
                  {`GetResult result = collection.get("post-1234");

String title = result.contentAsObject().getString("title");

/* title == "My Latest Blog Post" */`}
                </code>
              </pre>
            </span>

            {/* C# */}
            <span className={`mt-3 ${displayCodeFor !== 'c' ? 'hidden' : ''} c`}>
              <pre className="language-csharp pre codeblock line-numbers prism">
                <code data-language="c">
                  {`var result = await collection.GetAsync("post-1234");

var title = result.ContentAs<dynamic>().title.Value;

/* title == "My Latest Blog Post" IS THIS RIGHT? */`}
                </code>
              </pre>
            </span>

            {/* Node.js */}
            <span className={`mt-3 ${displayCodeFor !== 'javascript' ? 'hidden' : ''} javascript`}>
              <pre className="language-javascript pre codeblock line-numbers prism">
                <code data-language="javascript">
                  {`const result = await collection.get("post-1234");

const title = result.value['title'];

/* title == "My Latest Blog Post" IS THIS RIGHT? */`}
                </code>
              </pre>
            </span>

            {/* Python */}
            <span className={`mt-3 ${displayCodeFor !== 'python' ? 'hidden' : ''} python`}>
              <pre className="language-python pre codeblock line-numbers prism">
                <code data-language="python">
                  {`result = collection.get('post-1234')

result.content_as[str]

/* HOW TO GET THE TITLE of my blog post? */`}
                </code>
              </pre>
            </span>

            {/* PHP */}
            <span className={`mt-3 ${displayCodeFor !== 'php' ? 'hidden' : ''} php`}>
              <pre className="language-clike pre codeblock line-numbers prism">
                <code data-language="php">
                  {`$result = $collection->get("post-1234");

$title = $res->content()["title"];

/* $title == "My Latest Blog Post" */`}
                </code>
              </pre>
            </span>

            {/* Go */}
            <span className={`mt-3 ${displayCodeFor !== 'go' ? 'hidden' : ''} go`}>
              <pre className="language-go pre codeblock line-numbers prism">
                <code data-language="go">
                  {`result, err := collection.Get("post-1234", &gocb.GetOptions{})
var content struct{Title string 'json:"title"'}
err := result.Content(&content)
/* content.Title == "My Latest Blog Post" */
`}
                </code>
              </pre>
            </span>

            <p> And, you can use a N1QL <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/selectintro.html">SELECT</a> statement with
              the <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/hints.html#use-keys-clause">USE KEYS</a> clause…
            </p>

            <pre className="language-sql pre codeblock line-numbers prism">
              <code className="sql">
                {`SELECT title FROM posts USE KEYS "post-1234";`}
              </code>
            </pre>
          </div>
        </div>

        {/* *********************************** Filtering JSON docs by property ************************************ */}

        <div className="w-full text-center">
          <h2>Filtering JSON docs by property</h2>
          <p><strong>"How about filtering multiple JSON documents?"</strong></p>
          <p>Just like SQL, use a <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/where.html">WHERE</a> clause in
            a <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/selectintro.html">SELECT</a> statement…</p>
          <pre className="language-sql pre codeblock line-numbers prism">
            <code className="sql">
              {`SELECT title FROM posts WHERE published = true;`}
            </code>
          </pre>

          <div className="mobile_data-tabs p-4 md:p-8">
            <div className="mb-2">
              <p>Here’s how to execute that N1QL query statement using the SDK…​</p>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'java' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('java')}
              >Java</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'c' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('c')}
              >C#</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'javascript' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('javascript')}
              >Node.js</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'python' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('python')}
              >Python</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'php' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('php')}
              >PHP</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'go' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('go')}
              >go</button>
            </div>


            {/* Java */}
            <span className={`mt-3 ${displayCodeFor !== 'java' ? 'hidden' : ''} java`}>
              <pre className="language-java pre codeblock line-numbers prism">
                <code data-language="java">
                  {`QueryResult result = cluster.query(
    "SELECT title FROM posts WHERE published = true", queryOptions());

for (JsonObject row : result.rowsAsObject()) {
    System.out.println("published title: " + row.getString("title"));
}`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/java-sdk/current/howtos/kv-operations.html">SDK docs for Java</a></p>
            </span>

            {/* C# */}
            <span className={`mt-3 ${displayCodeFor !== 'c' ? 'hidden' : ''} c`}>
              <pre className="language-csharp pre codeblock line-numbers prism">
                <code data-language="c">
                  {`var results = await cluster.QueryAsync<dynamic>(
    "SELECT title FROM posts WHERE published = true");

await foreach (var row in results) {
    Console.WriteLine($"published title: {row.title}");
}`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/dotnet-sdk/current/howtos/kv-operations.html">SDK docs for .NET</a></p>
            </span>

            {/* Node.js */}
            <span className={`mt-3 ${displayCodeFor !== 'javascript' ? 'hidden' : ''} javascript`}>
              <pre className="language-javascript pre codeblock line-numbers prism">
                <code data-language="javascript">
                  {`let results = await cluster.query(
    "SELECT title FROM posts WHERE published = true")

results.rows.forEach((row) => {
    console.log("published title: " + row['title']) // WHAT IS A ROW?
})`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/nodejs-sdk/current/howtos/kv-operations.html">SDK docs for Node.js</a></p>
            </span>

            {/* Python */}
            <span className={`mt-3 ${displayCodeFor !== 'python' ? 'hidden' : ''} python`}>
              <pre className="language-python pre codeblock line-numbers prism">
                <code data-language="python">
                  {`result = cluster.query(
    "SELECT title FROM posts WHERE published = true")

for row in result:
    print("published title: " + row['title'])`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/python-sdk/current/howtos/kv-operations.html">SDK docs for Python</a></p>
            </span>

            {/* PHP */}
            <span className={`mt-3 ${displayCodeFor !== 'php' ? 'hidden' : ''} php`}>
              <pre className="language-clike pre codeblock line-numbers prism">
                <code data-language="php">
                  {`$result = $cluster->query(
    "SELECT title FROM posts WHERE published = true",
    new \\Couchbase\\QueryOptions());

foreach($result->rows() as $row) {
    printf("published title: %s", $row["title"]);
}`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/php-sdk/current/howtos/kv-operations.html">SDK docs for PHP</a></p>
            </span>

            {/* Go */}
            <span className={`mt-3 ${displayCodeFor !== 'go' ? 'hidden' : ''} go`}>
              <pre className="language-go pre codeblock line-numbers prism">
                <code data-language="go">
                  {`results, _ := cluster.Query(
    "SELECT title FROM posts WHERE published = true", nil)

for results.Next() {
    var row struct { Title string 'json:"title"' }
    results.Row(&row)
    fmt.Println("published title: " + row.Title)
}`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/go-sdk/current/howtos/kv-operations.html">SDK docs for Go</a></p>
            </span>
          </div>
        </div>


        {/* *********************************** SQL JOIN’s ************************************ */}

        <div className="w-full text-center">
          <h2>SQL JOIN’s</h2>
          <p><strong>"Can I do a JOIN?"</strong></p>
          <div className="mobile_data-tabs p-4 md:p-8">

            <p>Yes, several ways:</p>
            <p>Couchbase supports <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/join.html">ANSI JOIN</a>'s such as inner and left outer joins…​</p>

            {/* sql */}
            <pre className="language-sql pre codeblock line-numbers prism">
              <code data-language="sql">
                {`/* INNER JOIN */

SELECT authors.name, authors.avatar, posts.title
  FROM authors JOIN posts
    ON authors.id = posts.author_id;

/* LEFT OUTER JOIN */

SELECT authors.name, authors.avatar, posts.title
  FROM authors LEFT OUTER JOIN posts
    ON authors.id = posts.author_id;`}
              </code>
            </pre>

            <p>And there are <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/nest.html">NEST</a> and
              <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/unnest.html"> UNNEST</a> joins for hierarchical JSON data.</p>

            {/* sql */}
            <pre className="language-sql pre codeblock line-numbers prism">
              <code data-language="sql">
                {`SELECT posts.title, c.author_id as commenter_id
  FROM posts
UNNEST posts.comments AS c;

/*
{ "results": [
    { "title": "My Latest Blog Post", "commenter_id": "dave" },
    { "title": "My Latest Blog Post", "commenter_id": "jessica" }
  ]
}`}
              </code>
            </pre>


            <p>And there are <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/correlated-subqueries.html">correlated subqueries</a>.</p>

            <p>And <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/union.html">UNION, INTERSECT and EXCEPT</a> operators.</p>

            <p>And <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/with.html">WITH</a> and
              <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/let.html"> LET</a> clauses to help structure your queries.</p>
          </div>
        </div>


        {/* *********************************** Sub-document lookups ************************************ */}


        <div className="w-full text-center">
          <h2>Sub-document lookups</h2>
          <p><strong>"Can I read just parts of a JSON doc?"</strong></p>
          <div className="mobile_data-tabs p-4 md:p-8">
            <p>Couchbase supports efficient "sub-document" operations.</p>
            <p>To lookup part of a JSON doc…​</p>
            <p></p>
            <div className="mb-2">
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'java' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('java')}
              >Java</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'c' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('c')}
              >C#</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'javascript' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('javascript')}
              >Node.js</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'python' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('python')}
              >Python</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'php' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('php')}
              >PHP</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'go' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('go')}
              >go</button>
            </div>


            {/* Java */}
            <span className={`mt-3 ${displayCodeFor !== 'java' ? 'hidden' : ''} java`}>
              <pre className="language-java pre codeblock line-numbers prism">
                <code data-language="java">
                  {`LookupInResult result = collection.lookupIn(
    "customer-123",
    Collections.singletonList(get("addresses.delivery.country")));

String country = result.contentAs(0, String.class);`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/java-sdk/current/howtos/subdocument-operations.html">Sub-Document operations for Java</a></p>
            </span>

            {/* C# */}
            <span className={`mt-3 ${displayCodeFor !== 'c' ? 'hidden' : ''} c`}>
              <pre className="language-csharp pre codeblock line-numbers prism">
                <code data-language="c">
                  {`var result = await _collection.LookupInAsync(
    "customer-123",
    specs => specs.Get("addresses.delivery.country"));

string country = result.ContentAs<string>(0);`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/dotnet-sdk/current/howtos/subdocument-operations.html">Sub-Document operations for .NET</a></p>
            </span>

            {/* Node.js */}
            <span className={`mt-3 ${displayCodeFor !== 'javascript' ? 'hidden' : ''} javascript`}>
              <pre className="language-javascript pre codeblock line-numbers prism">
                <code data-language="javascript">
                  {`const result = await collection.lookupIn(
    "customer-123",
    [ couchbase.LookupInSpec.get("addresses.delivery.country") ]);

var country = result.content[0].value; // Ex: 'Canada'.`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/nodejs-sdk/current/howtos/subdocument-operations.html">Sub-Document operations for Node.js</a></p>
            </span>

            {/* Python */}
            <span className={`mt-3 ${displayCodeFor !== 'python' ? 'hidden' : ''} python`}>
              <pre className="language-python pre codeblock line-numbers prism">
                <code data-language="python">
                  {`result = collection.lookup_in(
    "customer-123",
    [ subdocument.get("addresses.delivery.country") ])

country = result.content_as[str](0) # Ex: "Canada".`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/python-sdk/current/howtos/subdocument-operations.html">Sub-Document operations for Python</a></p>
            </span>

            {/* PHP */}
            <span className={`mt-3 ${displayCodeFor !== 'php' ? 'hidden' : ''} php`}>
              <pre className="language-clike pre codeblock line-numbers prism">
                <code data-language="php">
                  {`$result = $collection->lookupIn(
    "customer-123",
    [ new \\Couchbase\\LookupGetSpec("addresses.delivery.country") ]);

$country = $result->content(0); // Ex: 'Canada'.`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/php-sdk/current/howtos/subdocument-operations.html">Sub-Document operations for PHP</a></p>
            </span>

            {/* Go */}
            <span className={`mt-3 ${displayCodeFor !== 'go' ? 'hidden' : ''} go`}>
              <pre className="language-go pre codeblock line-numbers prism">
                <code data-language="go">
                  {`result, err := collection.LookupIn(
    "customer-123",
    []gocb.LookupInSpec{
        gocb.GetSpec("addresses.delivery.country", &gocb.GetSpecOptions{}),
    },
    &gocb.LookupInOptions{})

var country string
err = getResult.ContentAt(0, &country)`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/go-sdk/current/howtos/subdocument-operations.html">Sub-Document operations for Go</a></p>
            </span>

            <p>And, you can use a <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/selectintro.html">SELECT</a> statement to access the fields you want from a JSON doc…​</p>

            <pre className="language-sql pre codeblock line-numbers prism">
              <code className="sql">
                {`SELECT addresses.delivery.country
  FROM customers
   USE KEYS "customer-123";

/* Select the subset of fields that you need... */

SELECT title, author, published_at, stars
  FROM posts
 WHERE published_at IS NOT NULL;`}
              </code>
            </pre>

          </div>
        </div>


        {/* *********************************** Sub-document mutations ************************************ */}

        <div className="w-full text-center">
          <h2>Sub-document mutations</h2>
          <p><strong>"Can I update just part of a JSON doc?"</strong></p>
          <div className="mobile_data-tabs p-4 md:p-8">
            <p>To update part of a JSON doc…​</p>
            <div className="mb-2">
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'java' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('java')}
              >Java</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'c' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('c')}
              >C#</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'javascript' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('javascript')}
              >Node.js</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'python' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('python')}
              >Python</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'php' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('php')}
              >PHP</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'go' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('go')}
              >go</button>
            </div>

            {/* Java */}
            <span className={`mt-3 ${displayCodeFor !== 'java' ? 'hidden' : ''} java`}>
              <pre className="language-java pre codeblock line-numbers prism">
                <code data-language="java">
                  {`collection.mutateIn("customer-123",
    Arrays.asList(upsert("email", "dougr96@hotmail.com")));`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/java-sdk/current/howtos/subdocument-operations.html">Sub-Document operations for Java</a></p>
            </span>

            {/* C# */}
            <span className={`mt-3 ${displayCodeFor !== 'c' ? 'hidden' : ''} c`}>
              <pre className="language-csharp pre codeblock line-numbers prism">
                <code data-language="c">
                  {`await _collection.MutateInAsync("customer-123",
    specs => specs.Upsert("email", "dougr96@hotmail.com"));`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/dotnet-sdk/current/howtos/subdocument-operations.html">Sub-Document operations for .NET</a></p>
            </span>

            {/* Node.js */}
            <span className={`mt-3 ${displayCodeFor !== 'javascript' ? 'hidden' : ''} javascript`}>
              <pre className="language-javascript pre codeblock line-numbers prism">
                <code data-language="javascript">
                  {`await collection.mutateIn("customer-123",
    [ couchbase.MutateInSpec.upsert("email", "dougr96@hotmail.com") ]);`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/nodejs-sdk/current/howtos/subdocument-operations.html">Sub-Document operations for Node.js</a></p>
            </span>

            {/* Python */}
            <span className={`mt-3 ${displayCodeFor !== 'python' ? 'hidden' : ''} python`}>
              <pre className="language-python pre codeblock line-numbers prism">
                <code data-language="python">
                  {`collection.mutate_in("customer-123",
    [ subdocument.upsert("email", "dougr96@hotmail.com") ])`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/python-sdk/current/howtos/subdocument-operations.html">Sub-Document operations for Python</a></p>
            </span>

            {/* PHP */}
            <span className={`mt-3 ${displayCodeFor !== 'php' ? 'hidden' : ''} php`}>
              <pre className="language-clike pre codeblock line-numbers prism">
                <code data-language="php">
                  {`$collection->mutateIn("customer-123",
    [ new \\Couchbase\\MutateUpsertSpec("email", "dougr96@hotmail.com") ]);`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/php-sdk/current/howtos/subdocument-operations.html">Sub-Document operations for PHP</a></p>
            </span>

            {/* Go */}
            <span className={`mt-3 ${displayCodeFor !== 'go' ? 'hidden' : ''} go`}>
              <pre className="language-go pre codeblock line-numbers prism">
                <code data-language="go">
                  {`collection.MutateIn("customer-123",
    []gocb.MutateInSpec{
        gocb.UpsertSpec("email", "dougr96@hotmail.com", &gocb.UpsertSpecOptions{}),
    },
    &gocb.MutateInOptions{})`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/go-sdk/current/howtos/subdocument-operations.html">Sub-Document operations for Go</a></p>
            </span>

            <p> And, you can use an <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/update.html">UPDATE</a> statement to mutate part of a doc with N1QL…​</p>

            <pre className="language-sql pre codeblock line-numbers prism">
              <code className="sql">
                {`/* Update a document with primary key "customer-123"... */

UPDATE customers USE KEYS "customer-123"
   SET email = "dougr96@hotmail.com";

/* Update documents based on a property condition... */

UPDATE customers
   SET city = "San Francisco"
 WHERE lower(city) = "sanfrancisco";`}
              </code>
            </pre>

          </div>
        </div>


        {/* *********************************** Doc deletion ************************************ */}

        <div className="w-full text-center">
          <h2>Doc deletion</h2>
          <p><strong>"How do I delete a JSON document?"</strong></p>
          <div className="mobile_data-tabs p-4 md:p-8">
            <div className="mb-2">
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'java' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('java')}
              >Java</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'c' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('c')}
              >C#</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'javascript' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('javascript')}
              >Node.js</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'python' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('python')}
              >Python</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'php' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('php')}
              >PHP</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'go' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('go')}
              >go</button>
            </div>


            {/* Java */}
            <span className={`mt-3 ${displayCodeFor !== 'java' ? 'hidden' : ''} java`}>
              <pre className="language-java pre codeblock line-numbers prism">
                <code data-language="java">
                  {`collection.remove("customer-123");`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/java-sdk/current/howtos/kv-operations.html">SDK docs for Java</a></p>
            </span>

            {/* C# */}
            <span className={`mt-3 ${displayCodeFor !== 'c' ? 'hidden' : ''} c`}>
              <pre className="language-csharp pre codeblock line-numbers prism">
                <code data-language="c">
                  {`await collection.RemoveAsync("customer-123");`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/dotnet-sdk/current/howtos/kv-operations.html">SDK docs for .NET</a></p>
            </span>

            {/* Node.js */}
            <span className={`mt-3 ${displayCodeFor !== 'javascript' ? 'hidden' : ''} javascript`}>
              <pre className="language-javascript pre codeblock line-numbers prism">
                <code data-language="javascript">
                  {`await collection.remove("customer-123")`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/nodejs-sdk/current/howtos/kv-operations.html">SDK docs for Node.js</a></p>
            </span>

            {/* Python */}
            <span className={`mt-3 ${displayCodeFor !== 'python' ? 'hidden' : ''} python`}>
              <pre className="language-python pre codeblock line-numbers prism">
                <code data-language="python">
                  {`collection.remove("customer-123")`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/python-sdk/current/howtos/kv-operations.html">SDK docs for Python</a></p>
            </span>

            {/* PHP */}
            <span className={`mt-3 ${displayCodeFor !== 'php' ? 'hidden' : ''} php`}>
              <pre className="language-clike pre codeblock line-numbers prism">
                <code data-language="php">
                  {`$collection->remove("customer-123");`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/php-sdk/current/howtos/kv-operations.html">SDK docs for PHP</a></p>
            </span>

            {/* Go */}
            <span className={`mt-3 ${displayCodeFor !== 'go' ? 'hidden' : ''} go`}>
              <pre className="language-go pre codeblock line-numbers prism">
                <code data-language="go">
                  {`collection.Remove("customer-123", &gocb.RemoveOptions{})`}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/go-sdk/current/howtos/kv-operations.html">SDK docs for Go</a></p>
            </span>

            <p> And, you can use a N1QL <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/delete.html">DELETE</a> statement…​</p>

            <pre className="language-sql pre codeblock line-numbers prism">
              <code className="sql">
                {`/* Delete document with primary key "customer-123" */

DELETE FROM customers USE KEYS "customer-123";

/* Delete documents based on a property condition... */

DELETE FROM customers WHERE lastActivityYear <= "2005";`}
              </code>
            </pre>

          </div>
        </div>

        {/* *********************************** Expiring data ************************************ */}

        <div className="w-full text-center">
          <h2>Expiring data</h2>
          <p><strong>"How about document expirations?"</strong></p>
          <div className="mobile_data-tabs p-4 md:p-8">
            <p>When you create or update a document, you can provide an optional expiration time — which is useful for sessions and data caching.</p>
            <div className="mb-2">
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'java' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('java')}
              >Java</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'c' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('c')}
              >C#</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'javascript' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('javascript')}
              >Node.js</button>

              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'python' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('python')}
              >Python</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'php' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('php')}
              >PHP</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'go' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('go')}
              >go</button>
            </div>


            {/* Java */}
            <span className={`mt-3 ${displayCodeFor !== 'java' ? 'hidden' : ''} java`}>
              <pre className="language-java pre codeblock line-numbers prism">
                <code data-language="java">
                  {`MutationResult result = collection.upsert(
    "cart-2222",
    shoppingCartJsonObject,
    upsertOptions().expiry(Duration.ofHours(2)));`}
                </code>
              </pre>
            </span>

            {/* C# */}
            <span className={`mt-3 ${displayCodeFor !== 'c' ? 'hidden' : ''} c`}>
              <pre className="language-csharp pre codeblock line-numbers prism">
                <code data-language="c">
                  {`var result = await collection.UpsertAsync(
    "cart-2222",
    shoppingCartObject,
    options => { options.WithExpiration(TimeSpan.FromHours(2)); })`}
                </code>
              </pre>
            </span>

            {/* Node.js */}
            <span className={`mt-3 ${displayCodeFor !== 'javascript' ? 'hidden' : ''} javascript`}>
              <pre className="language-javascript pre codeblock line-numbers prism">
                <code data-language="javascript">
                  {`const result = await collection.upsert(
    "cart-2222",
    shoppingCartObject,
    { expiry: 2 * 60 * 60 }) // 2 hours in seconds.`}
                </code>
              </pre>
            </span>

            {/* Python */}
            <span className={`mt-3 ${displayCodeFor !== 'python' ? 'hidden' : ''} python`}>
              <pre className="language-python pre codeblock line-numbers prism">
                <code data-language="python">
                  {`result = collection.upsert(
    "cart-2222",
    shoppingCartObject,
    expiry=timedelta(hours=2))`}
                </code>
              </pre>
            </span>

            {/* PHP */}
            <span className={`mt-3 ${displayCodeFor !== 'php' ? 'hidden' : ''} php`}>
              <pre className="language-clike pre codeblock line-numbers prism">
                <code data-language="php">
                  {`$opts = new UpsertOptions();
$opts->expiry(2 * 60 * 60 /* 2 hours in seconds */);

$result = $collection->upsert(
    "cart-2222", $shoppingCartObject, $opts);`}
                </code>
              </pre>
            </span>

            {/* Go */}
            <span className={`mt-3 ${displayCodeFor !== 'go' ? 'hidden' : ''} go`}>
              <pre className="language-go pre codeblock line-numbers prism">
                <code data-language="go">
                  {`result, err := collection.Upsert(
    "cart-2222",
    shoppingCartObject,
    &gocb.UpsertOptions{ Expiry: 2 * time.Hour })`}
                </code>
              </pre>
            </span>

            <p> And, you can use a N1QL <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/insert.html">INSERT</a> or
              <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/upsert.html"> UPSERT</a> statement…​ </p>

            <pre className="language-sql pre codeblock line-numbers prism">
              <code className="sql">
                {`UPSERT INTO carts (KEY, VALUE, OPTIONS)
       VALUES ( "cart-2222",
                { "cartItems": [ ... ] },
                { "expiration": 2 * 60 * 60 } );
  !!! ??? Is the expration seconds???`}
              </code>
            </pre>

          </div>
        </div>

        {/* *********************************** "What other JSON doc operations can I perform?" ************************************ */}
        {/* CODE TEMPLATE BELOW FOR FUTURE REFERENCE */}
        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"What other JSON doc operations can I perform?"</strong></p>
          <p>CAS append/replace/etc increment/decrement</p>
          {/* <div className="mobile_data-tabs p-4 md:p-8">
            <div className="mb-2">
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'java' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('java')}
              >Java</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'c' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('c')}
              >C#</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'javascript' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('javascript')}
              >Node.js</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'python' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('python')}
              >Python</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'php' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('php')}
              >PHP</button>
              <button className={`py-2 px-3 bg-blue-400 text-white mx-0 sm:mx-0 md:mx-2 lg:mx-2 border-2 border-blue-600 
              text-xs sm:text-sm md:text-base lg:text-base 
              hover:bg-white font-normal hover:text-blue-600 mb-4
              ${displayCodeFor === 'go' ? 'bg-blue-800' : ''}`}
                onClick={() => setDisplayCodeFor('go')}
              >go</button>
            </div>
            <span className={`mt-3 ${displayCodeFor !== 'java' ? 'hidden' : ''} java`}>
              <pre className="language-java pre codeblock line-numbers prism">
                <code data-language="java">
                  {``}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="">SDK docs for Java</a></p>
            </span>
            <span className={`mt-3 ${displayCodeFor !== 'c' ? 'hidden' : ''} c`}>
              <pre className="language-csharp pre codeblock line-numbers prism">
                <code data-language="c">
                  {``}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="">SDK docs for .NET</a></p>
            </span>
            <span className={`mt-3 ${displayCodeFor !== 'javascript' ? 'hidden' : ''} javascript`}>
              <pre className="language-javascript pre codeblock line-numbers prism">
                <code data-language="javascript">
                  {``}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="">SDK docs for Node.js</a></p>
            </span>
            <span className={`mt-3 ${displayCodeFor !== 'python' ? 'hidden' : ''} python`}>
              <pre className="language-python pre codeblock line-numbers prism">
                <code data-language="python">
                  {``}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="">SDK docs for Python</a></p>
            </span>
            <span className={`mt-3 ${displayCodeFor !== 'php' ? 'hidden' : ''} php`}>
              <pre className="language-clike pre codeblock line-numbers prism">
                <code data-language="php">
                  {``}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="">SDK docs for PHP</a></p>
            </span>
            <span className={`mt-3 ${displayCodeFor !== 'go' ? 'hidden' : ''} go`}>
              <pre className="language-go pre codeblock line-numbers prism">
                <code data-language="go">
                  {``}
                </code>
              </pre>
              <p>More info: <a target="_blank" rel="noopener noreferrer" href="">SDK docs for Go</a></p>
            </span>
            <p> And, you can use a N1QL <a target="_blank" rel="noopener noreferrer" href=""></a></p>
            <pre className="language-sql pre codeblock line-numbers prism">
              <code className="sql">
                {``}
              </code>
            </pre>
          </div> */}
        </div>

        {/* *********************************************************************************** */}
        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"How do I dig deep into a nested JSON doc with N1QL?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"What is the 'memory-first' approach of Couchbase?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"Does Couchbase support transactions?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"Does Couchbase support async operations?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"What do I need to change if I add more Couchbase servers to a cluster?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"Do cluster membership changes need downtime?"</strong></p>
          <p>No downtime needed.</p>
          <p>A <a target="_blank" rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/learn/clusters-and-availability/rebalance.html">Rebalance</a> operation is handled by Couchbase with the automatic movement and the clean handoff of data partitions amongst the server nodes with no downtime.</p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"Does Couchbase support indexing?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"Does Couchbase support nested subqueries?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"Can I perform GROUP BY’s and aggregates with Couchbase?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"How about the WITH clause?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"How about the functions in N1QL?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"What do window functions look like in Couchbase?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"How about complex queries and analysis?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"How can my app be notified about data changes?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"What does multi-datacenter replication look like?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"What does Couchbase mobile look like?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"What does backup / restore look like in Couchbase?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"Does Couchbase run on Kubernetes?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

        <div className="w-full text-center">
          <h2><br /></h2>
          <p><strong>"How do I download Couchbase?"</strong></p>
        </div>

        {/* *********************************************************************************** */}

      </div>

    </div>
  )
}
export default NewToCouchbaseCode